import { render, staticRenderFns } from "./ProductClass.vue?vue&type=template&id=813d9524&scoped=true&functional=true&"
import script from "./ProductClass.vue?vue&type=script&lang=js&"
export * from "./ProductClass.vue?vue&type=script&lang=js&"
import style0 from "./ProductClass.vue?vue&type=style&index=0&id=813d9524&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "813d9524",
  null
  
)

export default component.exports